<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyword"
            size="medium"
            placeholder="合作标题"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.status" placeholder="状态" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="未审核" :value="0" />
            <el-option label="审核未通过" :value="2" />
            <el-option label="审核通过" :value="1" />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.type" placeholder="类型" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(item, index) in configDictionaries.cooperationType"
              :label="item.value"
              :value="item.key"
              :key="index"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.business" placeholder="行业" size="medium">
            <el-option label="全部" value="-1" />
            <el-option
              v-for="(item, index) in configDictionaries.cooperationBusiness"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.field" placeholder="领域" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(item, index) in configDictionaries.cooperationField"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.area" placeholder="服务区域" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(item, index) in configDictionaries.cooperationServerArea"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            v-model="form_2.recommend"
            placeholder="Banner推荐"
            size="medium"
          >
            <el-option label="全部" value="-1" />
            <el-option
              v-for="(item, index) in recommendList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 250px;">
          <el-date-picker
            v-model="form_2.createDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            size="medium"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="realName" label="姓名" >
          <template slot-scope="{ row }">
            <div @click="toUser(row)" style="cursor: pointer;">
              {{ row.realName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="{ row }">
            <el-tag
              :type="row.type == 1 ? '' : 'warning'"
              size="small"
              class="box"
              >{{ show_type(row.type) }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="合作标题"
          width="150"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="content"
          label="合作内容"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="{ row }">
            <p
              style="width: 180px; overflow: hidden;white-space: nowrap;text-overflow:ellipsis;"
            >
              {{ row.content }}
            </p>
            <el-image
              v-for="item in fProductPicture(row.productPicture)"
              :key="item"
              :src="item"
              style="width: 35px;height:35px;margin:4px"
              :preview-src-list="[item]"
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="行业" width="140">
          <template slot-scope="{ row }">
            <!--                        <el-tag-->
            <!--                            v-for="(item, index) in row.businessName"-->
            <!--                            :key="index"-->
            <!--                            type="warning"-->
            <!--                            size="small"-->
            <!--                            class="box"-->
            <!--                            >{{ item }}</el-tag-->
            <!--                        >-->
            <div>
              <el-select
                  v-model="row.business"
                  placeholder="行业"
                  multiple
                  :multiple-limit="3"
                  size="medium"
                  @change="selectChagne(row)"
                  @visible-change="visibleChange"
                  @remove-tag="handleRemove"
              >
                <el-option
                    v-for="(item, index) in configDictionaries.cooperationBusiness"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                />
              </el-select>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="fieldName" label="领域" width="140">
          <template slot-scope="{ row }">
            <!--                        <el-tag-->
            <!--                            v-for="(item, index) in row.fieldName"-->
            <!--                            :key="index"-->
            <!--                            type="warning"-->
            <!--                            size="small"-->
            <!--                            class="box"-->
            <!--                            >{{ item }}</el-tag-->
            <!--                        >-->
            <div>
              <el-select
                  v-model="row.field"
                  placeholder="行业"
                  multiple
                  :multiple-limit="3"
                  size="medium"
                  @change="selectChagne(row)"
                  @visible-change="visibleChange"
                  @remove-tag="handleRemove"
              >
                <el-option
                    v-for="(item, index) in configDictionaries.cooperationField"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                />
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="区域" width="140">
          <template slot-scope="{ row }">
            <!--                        <el-tag-->
            <!--                            v-for="(item, index) in row.areaName"-->
            <!--                            :key="index"-->
            <!--                            type="warning"-->
            <!--                            size="small"-->
            <!--                            class="box"-->
            <!--                            >{{ item }}</el-tag-->
            <!--                        >-->
            <div>
              <el-select
                  v-model="row.area"
                  placeholder="行业"
                  multiple
                  :multiple-limit="3"
                  size="medium"
                  @change="selectChagne(row)"
                  @visible-change="visibleChange"
                  @remove-tag="handleRemove"
              >
                <el-option
                    v-for="(item, index) in configDictionaries.cooperationServerArea"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                />
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="endTimeType" label="合作截至时间">
          <template slot-scope="{ row }">
            <p>{{ row.endTimeType == 0 ? '长期有效' : row.endTime }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="添加时间" />

        <el-table-column prop="status" label="状态">
          <template slot-scope="{ row }">
            <el-tag type="info" size="small" class="box" v-if="row.status==2">
              {{
                show_status(row.status)
              }}
            </el-tag>
            <el-tag type="success" size="small" class="box" v-else-if="row.status==1">
              {{
                show_status(row.status)
              }}
            </el-tag>
            <el-tag type="warning" size="small" class="box" v-else>
              {{
                show_status(row.status)
              }}
            </el-tag>

          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"  :show-overflow-tooltip="true" />
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              v-show="$store.state.btn_if.indexOf('cooperation_edit') != -1"
              type="text"
              icon="el-icon-edit"
              size="mini"
              style="color:skyblue"
              @click="edit(scope.row.id)"
              >编辑
            </el-button>
            <el-button
              v-show="$store.state.btn_if.indexOf('cooperation_check') != -1"
              type="text"
              icon="el-icon-setting"
              size="mini"
              @click="setRole(scope.row, 1)"
              >审核通过
            </el-button>
            <el-button
              v-show="$store.state.btn_if.indexOf('cooperation_check') != -1"
              type="text"
              icon="el-icon-setting"
              size="mini"
              class="red"
              @click="showShk(scope.row)"
              >审核不通过</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <dilog
      :dialog-visible.sync="dialogVisible"
      :form="dia_form"
      @getlist="getlist"
    />
  </div>
</template>

<script>
let that
import rules from '../../utils/rules'
import show from '../../components/countDowns/show.vue'
// import mixins from '../../mixin/getdiction'
import remark from '../../components/remark/index'
import Dilog from './cooperation_cert_list/dilog.vue'
import {recommendList} from './data.js'
export default {
  components: {
    Show: show,
    Remark: remark,
    Dilog
  },
  data() {
    return {
      title: '',
      year: '',
      publishStatus: '',
      reportArea: '',
      reportBusiness: '',
      order: '',
      confing: '',
      btn: true,
      action: {
        action: 'enterprise'
      },
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 10,
      list: [],
      pai: [],
      paiform: {},
      value: '',
      dialogVisible: false,
      dia_form: {},
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      proof: false,
      form: {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: '',
        isHide: '',
        type: '',
        area: '',
        field: '',
        status:'',
        createDate: [],
        recommend: ''
        // order: ''
      },
      form_3: {
        phone: this.$cookies.get('phone')
      },
      s_form: {
        page: 1,
        pageSize: 10,
        keyword: '',
        status: '',
        type: '',
        area: '',
        field: '',
        createDate: [],
        recommend: ''
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      form_show: {},
      area: [],
      configDictionaries: {},
      recommendList
    }
  },
  computed: {
    show_status() {
      return val => {
        var key
        switch (val) {
          case 0:
            key = '待审核'
            break
          case 1:
            key = '审核通过'
            break
          case 2:
            key = '审核不通过'
            break
        }
        return key
      }
    }
  },
  // mixins: [mixins],
  beforeCreate() {
    that = this
  },
  updated() {
    //   console.log(this.form);
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.$http
      .get('/admin/Common/getConfigDictionaries?config=cooperationNew')
      .then(({ data: res }) => {
        this.configDictionaries = res.data
      })
    this.getlist()
    sessionStorage.setItem('action', 'cooperation')
  },

  watch: {
    '$route'(to, from) {
      let that = this
      that.getlist()
      console.log('list')
    }
  },
  mounted() {
    // console.log(rules.req('撒打算'))
    // console.log(this.confing_d)
  },
  methods: {
    /**
     * 姓名点击跳转至会员
     * @param val
     */
    toUser(val) {
      // console.log(val);
      let { userId } = val;
      this.$router.push({
        path: "/user_list",
        query: {
          userId,
        },
      });
    },
    reset() {
      this.form_2 = {
        page: 1,
        pageSize: 10,
        keyword: '',
        isHide: '',
        type: '',
        area: '',
        field: '',
        status:'',
        createDate: [],
        recommend: ''
        // order: ''
      }
      this.s_form = { ...this.form_2 }

      this.getlist()
      this.$http
        .get('/admin/Common/getConfigDictionaries?config=cooperationNew')
        .then(({ data: res }) => {
          this.configDictionaries = res.data
        })
    },
    fProductPicture(val) {
      if (val.includes('http')) {
        return val.split(',')
      } else {
        return []
      }
    },

    subimt() {
      this.currentPage = 1
      this.form_2.page = 1
      this.s_form = { ...this.form_2 }
      this.getlist()
    },
    show_type(val) {
      if (val == 1) {
        return '提供服务'
      } else {
        return '需要资源'
      }
    },
    check() {
      console.log(1)
    },
    showShk(row) {
      this.dialogVisible = true
      this.dia_form = row
    },
    setRole(row, info) {
      this.$http
        .post('/admin/Cooperation/check', {
          ...row,
          status: info
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    },

    information(row) {
      this.showinfo = true
      // this.form_show = row
      this.$http
        .get('/admin/UserSpeaker/getById', { params: { id: row.id } })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form_show = res.data
          }
        })
    },
    datum(row) {
      location.href = this.baseurl + this.yu + row.document
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else if (c.prop == 'views') {
          s = 'report_views'
        } else {
          s = 'report_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },

    addkuai() {
      this.addkuai = false
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
        }
      })
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      // this.getlist()
      this.s_form.page = e
      this.currentPage = e
      this.getlist(this.s_form)
    },
    handleRemove(){
      this.visibleChange()
      // this.$confirm('是否确定删除？', '提示', {
      //   confirmButtonText: '是',
      //   cancelButtonText: '否',
      //   type: 'warning'
      // }).then(() => {
      //
      // }).catch(() => {
      //   this.getlist()
      //
      // });
    },
    selectChagne(row, val) {
      this.row=row
      // if(val==3){
      //   this.$http
      //       .post("/admin/Cooperation/editBusinessFieldArea", {
      //         id:row.id,
      //         business:row.business.toString(),
      //         field:row.field.toString(),
      //         area:row.area.toString()
      //       })
      //       .then(({ data: res }) => {
      //         if (res.errorCode == 200) {
      //          this.getlist()
      //         } else {
      //           this.getlist()
      //         }
      //       });
      //
      // }
    },
    visibleChange(e){
      if(e){
        return
      }else {
        this.$confirm('是否修改', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          this.$http
              .post("/admin/Cooperation/editBusinessFieldArea", {
                id:this.row.id,
                business:this.row.business.toString(),
                field:this.row.field.toString(),
                area:this.row.area.toString()
              })
              .then(({ data: res }) => {
                if (res.errorCode == 200) {
                  this.getlist();
                  this.$message("修改成功")
                } else {
                  this.getlist();
                }
              });
        }).catch(() => {
        });

      }

    },
    getlist(val) {
      var form = val || this.form_2
      this.$http
        .get('/admin/Cooperation/getCertList', {
          params: {
            ...form,
            createDate: form.createDate.join('~'),
            status: form.status == -1 ? '' : form.status,
            type: form.type == -1 ? '' : form.type,
            field: form.field == -1 ? '' : form.field,
            area: form.area == -1 ? '' : form.area
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res)
            this.list = res.data.list.map(item=>{
              let i = {
                ...item,
                area:item.area.split(",").map(j=>{
                  return Number(j)
                }),
                business:item.business.split(",").map(j=>{
                  return Number(j)
                }),
                field:item.field.split(",").map(j=>{
                  return Number(j)
                })
              }
              return i
            });
            // console.log(this.list)
            this.total = res.data.totalCount
          }
        })
    },
    checkf(val) {
      this.form_3 = val
      // console.log(this.form_3)
    },
    async beforedown() {
      const phone = this.$cookies.get('phone')
      if (!phone) {
        this.$message.error('没有绑定手机号')
        return
      }
      if (!this.proof) {
        this.dioA = true
      } else {
        this.download()
      }
    },
    async download() {
      if (this.btn) {
        this.btn = false
        // const { data: res } = await this.$http.get(
        //   '/admin/Common/checkSmsCode',
        //   {
        //     params: {
        //       ...this.form_3
        //     }
        //   }
        // )
        // this.btn = true
        // // console.log(this.btn)
        // this.$forceUpdate()
        if (false) {
          // this.$message.error(res.message)
          // return
        } else {
          const { data: res } = await this.$http.get(
            '/admin/UserSpeaker/exportExcel',
            {
              params: {
                ...this.form_2,
                ...this.form_3
              }
            }
          )
          this.proof = true
          this.btn = true
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.href = res.data.url
            this.dioA = false
          }
        }
      }
    },
    add() {},
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },

    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    addedit() {
      if (this.btn) {
        // this.form.picture = this.form.picture.slice(this.baseurl.length)

        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.dialogVisiblexui = false
              this.$message.success(res.message)
              this.getlist()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      // sessionStorage.setItem('flag', false)
      // this.p_a = true

      this.$router.push({
        name: 'cooperation_list_add',
        query: {
          id
        }
      })
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/cooperation/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    setTop(row, val) {
      // console.log(1)
      // console.log(row, id)
      this.$http
        .post('/admin/cooperation/top', {
          id: row.id,
          top: val
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              customClass: 'zZindex'
            })
          }
          // this.btn1 = true
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/Cooperation/hide', { id: id, isHide: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    },
    chagne(row) {
      this.$http
        .post('/admin/UserSpeaker/editService', {
          id: row.id,
          waId: row.waId.join(',')
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.getlist()
          } else {
            this.getlist()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
.box {
  display: inline-block;
  margin: 5px;
}
</style>
